import { LegacyMigrationStatus } from "./legacy";
import { getName, getFirstLetter, UserRole } from "./user";

export class Member {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  name: string;
  userRole: UserRole;
  createdAt: Date;
  updatedAt: Date;

  constructor(dbModel: any) {
    if (!dbModel) {
      throw new Error("error constructing db user");
    }
    this.id = dbModel?.id;
    this.email = dbModel?.email;
    this.firstName = dbModel?.first_name;
    this.lastName = dbModel?.last_name;
    this.name = dbModel?.name;
    this.userRole = dbModel?.user_role;
    this.createdAt = new Date(dbModel?.created_at);
    this.updatedAt = new Date(dbModel?.updated_at);
  }

  getName() {
    return getName(this.firstName, this.lastName, this.name);
  }

  getFirstLetter() {
    return getFirstLetter(this.getName());
  }

  getRole() {
    return this.userRole
      .toLowerCase()
      .split(" ")
      .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(" ");
  }
}

export type StripeSubscriptionStatus =
  | "incomplete"
  | "incomplete_expired"
  | "trialing"
  | "active"
  | "past_due"
  | "canceled"
  | "unpaid";

export class Account {
  id: string;
  name: string;
  invitationCode: string;
  members: Member[];
  demo: boolean;
  defaultCurrencyCode: string;
  legacyMigrationStatus: LegacyMigrationStatus | null;
  legacyMigrationCompletedAt: Date | null;
  stripeCustomerId: string | null;
  stripeSubscriptionId: string | null;
  stripeSubscriptionStatus: StripeSubscriptionStatus | null;
  stripeSubscriptionPeriodEnd: Date | null;
  stripePriceId: string | null;
  createdAt: Date;
  updatedAt: Date;

  constructor(dbModel: any) {
    if (!dbModel) {
      throw new Error("error constructing account");
    }
    this.id = dbModel?.id;
    this.name = dbModel?.name;
    this.invitationCode = dbModel?.invitation_code;
    this.members = dbModel?.members
      ?.map((member: any) => new Member(member))
      .sort((member1: Member, member2: Member) => {
        return member1.createdAt.getTime() - member2.updatedAt.getTime();
      });
    this.demo = dbModel?.demo;
    this.defaultCurrencyCode = dbModel.default_currency_code;
    this.legacyMigrationStatus = dbModel?.legacy_migration_status;
    this.legacyMigrationCompletedAt = dbModel?.legacy_migration_completed_at
      ? new Date(dbModel.legacy_migration_completed_at)
      : null;
    this.stripeCustomerId = dbModel?.stripe_customer_id ?? null;
    this.stripeSubscriptionId = dbModel?.stripe_subscription_id ?? null;
    this.stripeSubscriptionStatus = dbModel?.stripe_subscription_status ?? null;
    this.stripeSubscriptionPeriodEnd = dbModel?.stripe_subscription_period_end
      ? new Date(dbModel.stripe_subscription_period_end)
      : null;
    this.stripePriceId = dbModel?.stripe_price_id;
    this.createdAt = new Date(dbModel?.created_at);
    this.updatedAt = new Date(dbModel?.updated_at);
  }
}
