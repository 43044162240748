import { Transition } from "@headlessui/react";
import * as DialogPrimitive from "@radix-ui/react-dialog";
import { Fragment, ReactNode, useCallback } from "react";
import { Button } from "legacy-ui";
import { cn } from "utils";

export default function ConfirmationModal({
  open,
  setOpen,
  title,
  cancelText = "Cancel",
  saveText = "Save",
  href,
  handle,
  clear,
  variant,
  newTab,
  children,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  title: string;
  cancelText?: string;
  saveText?: string | null;
  href?: string;
  handle?: () => void;
  clear?: () => void;
  variant?: "primary" | "docs";
  newTab?: boolean;
  children?: ReactNode;
}) {
  const closeModal = useCallback(() => {
    setOpen(false);
    if (clear) clear();
  }, [clear, setOpen]);

  async function handleButton() {
    if (handle) {
      try {
        await handle();
      } catch (error) {}
    }

    closeModal();
  }

  return (
    <DialogPrimitive.Root
      open={open}
      onOpenChange={(open: boolean) => {
        if (open) setOpen(true);
        else closeModal();
      }}
    >
      {/* https://github.com/radix-ui/primitives/issues/1386 */}
      {/* <DialogPrimitive.Portal forceMount> */}
      <Transition.Root show={open}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <DialogPrimitive.Overlay
            forceMount
            className="fixed inset-0 z-[60] overflow-y-auto bg-black/30"
          >
            <div className="flex min-h-full items-center justify-center p-4">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <DialogPrimitive.Content
                  forceMount
                  className={cn(
                    "my-auto w-full max-w-96 rounded-xl bg-white dark:bg-legacy-neutral-base"
                  )}
                >
                  <DialogPrimitive.Title className="flex h-16 items-center justify-between px-4 tablet:px-6">
                    <p className="text-xl">{title}</p>
                    {/*<button onClick={closeModal}>
                        <XIcon className="h-5 w-5 text-legacy-neutral-500 hover:text-legacy-neutral-900 dark:hover:text-legacy-neutral-200"></XIcon>
                      </button>*/}
                  </DialogPrimitive.Title>

                  <div className="px-4 text-sm tablet:px-6">{children}</div>

                  <div className="mt-10 flex h-16 items-center justify-end gap-3 px-4 tablet:px-6">
                    <Button variant="text_secondary" onClick={closeModal}>
                      {cancelText}
                    </Button>

                    {saveText !== null ? (
                      <Button
                        variant={variant ?? (href ? "docs" : "primary")}
                        onClick={handleButton}
                        href={href}
                        newTab={newTab ?? !!href}
                      >
                        {saveText}
                      </Button>
                    ) : null}
                  </div>
                </DialogPrimitive.Content>
              </Transition.Child>
            </div>
          </DialogPrimitive.Overlay>
        </Transition.Child>
      </Transition.Root>
      {/* </DialogPrimitive.Portal> */}
    </DialogPrimitive.Root>
  );
}
