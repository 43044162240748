export async function callBackendPromise({
  uri = "",
  method = "GET",
  data = {},
  userId = "",
  accountId = "",
  accessToken = "",
}) {
  const headers: { [key: string]: string } = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + accessToken,
    "User-ID": userId,
  };
  if (accountId.length > 0) {
    headers["Account-Id"] = accountId;
  }
  const apiEndpoint =
    process.env.NODE_ENV === "development" && typeof window !== "undefined"
      ? `http://${window.location.hostname}:3001`
      : process.env.NEXT_PUBLIC_API_ENDPOINT;
  return await fetch(apiEndpoint + uri, {
    method,
    headers: headers,
    body: method === "GET" ? undefined : JSON.stringify(data),
    redirect: "follow",
  });
}

export default async function callBackend({
  uri = "",
  method = "GET",
  data = {},
  userId = "",
  accountId = "",
  accessToken = "",
}) {
  return await callBackendPromise({
    uri,
    method,
    data,
    userId,
    accountId,
    accessToken,
  });
}
